@use 'global' as *;
.container {
  @include container;

  margin-bottom: 20px;
  margin-top: 20px;
  position: relative;
  z-index: var(--z-index-plus-one);

  @include media($small-lowest) {
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .header {
    margin: 40px auto;
    text-align: center;
  }

  .cards_container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    width: calc(100% + 30px);

    > * {
      margin: 15px;
      width: calc(100% - 30px);

      @include media($small-lowest) {
        width: calc(50% - 30px);
      }

      @include media($medium-lowest) {
        width: calc(100% / 3 - 30px);
      }

      > * {
        height: 100%;
      }
    }
  }

  > p {
    color: var(--color-prime-gray);
    font-size: 22px;
    text-align: center;
  }
}

.button_container {
  @include container;

  padding-bottom: 80px;
  padding-top: 30px;
  text-align: center;
}
